var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gauge" }, [
    _vm.height
      ? _c(
          "svg",
          {
            attrs: {
              viewBox: `0 0 ${_vm.RADIUS * 2} ${_vm.height}`,
              height: "100%",
              width: "100%",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c(
              "defs",
              [
                _c(
                  "filter",
                  { attrs: { id: `innershadow-${_vm._uid}` } },
                  [
                    _c("feFlood", {
                      attrs: { "flood-color": _vm.shadowColor },
                    }),
                    _c("feComposite", {
                      attrs: { in2: "SourceAlpha", operator: "out" },
                    }),
                    _c("feGaussianBlur", {
                      attrs: { stdDeviation: "2", result: "blur" },
                    }),
                    _c("feComposite", {
                      attrs: { operator: "atop", in2: "SourceGraphic" },
                    }),
                  ],
                  1
                ),
                _vm.hasGradient
                  ? _c(
                      "linearGradient",
                      { attrs: { id: `gaugeGradient-${_vm._uid}` } },
                      _vm._l(_vm.gaugeColor, function (color, index) {
                        return _c("stop", {
                          key: `${color.color}-${index}`,
                          attrs: {
                            offset: `${color.offset}%`,
                            "stop-color": color.color,
                          },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _c(
                  "mask",
                  { attrs: { id: `innerCircle-${_vm._uid}` } },
                  [
                    _c("circle", {
                      attrs: {
                        r: _vm.RADIUS - 0.5,
                        cx: _vm.X_CENTER,
                        cy: _vm.Y_CENTER,
                        fill: "white",
                      },
                    }),
                    _c("circle", {
                      attrs: {
                        r: _vm.innerRadius,
                        cx: _vm.X_CENTER,
                        cy: _vm.Y_CENTER,
                        fill: "black",
                      },
                    }),
                    _vm.separatorPaths
                      ? _vm._l(_vm.separatorPaths, function (separator, index) {
                          return _c("path", {
                            key: index,
                            attrs: { d: separator, fill: "black" },
                          })
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
            _c("g", { attrs: { mask: `url(#innerCircle-${_vm._uid})` } }, [
              _vm.isCircle
                ? _c("circle", {
                    attrs: {
                      r: _vm.RADIUS,
                      cx: _vm.X_CENTER,
                      cy: _vm.Y_CENTER,
                      fill: _vm.hasGradient
                        ? `url(#gaugeGradient-${_vm._uid})`
                        : _vm.gaugeColor,
                    },
                  })
                : _c("path", {
                    attrs: {
                      d: _vm.basePath,
                      fill: _vm.hasGradient
                        ? `url(#gaugeGradient-${_vm._uid})`
                        : _vm.gaugeColor,
                    },
                  }),
              _vm.value === _vm.min && _vm.isCircle
                ? _c("circle", {
                    attrs: {
                      r: _vm.RADIUS,
                      cx: _vm.X_CENTER,
                      cy: _vm.Y_CENTER,
                      fill: _vm.baseColor,
                    },
                  })
                : _c("path", {
                    attrs: {
                      d: _vm.gaugePath,
                      fill: _vm.baseColor,
                      filter: `url(#innershadow-${_vm._uid})`,
                    },
                  }),
            ]),
            _vm.scaleLines
              ? _vm._l(_vm.scaleLines, function (line, index) {
                  return _c("line", {
                    key: `${line.xE}-${index}`,
                    attrs: {
                      x1: line.xS,
                      y1: line.yS,
                      x2: line.xE,
                      y2: line.yE,
                      "stroke-width": "1",
                      stroke: _vm.baseColor,
                    },
                  })
                })
              : _vm._e(),
            _c(
              "foreignObject",
              { attrs: { x: "0", y: "0", width: "100%", height: _vm.height } },
              [_vm._t("default")],
              2
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }